.polit-txt
  &__item
    display: flex
    gap: 1.8rem
    padding-bottom: 5rem
    margin-bottom: 3rem
    border-bottom: 1px solid var(--osnovnye-grey-dark)
    +screen('max', 767)
      display: block
      padding-bottom: 4rem
      margin-bottom: 2rem
    &:last-of-type
      margin-bottom: 0
      padding-bottom: 0
      border-bottom: 0
  &__over-title
    position: relative
    width: 290px
    flex-shrink: 0
    +screen('max', 1023)
      width: 250px
  &__title
    position: sticky
    top: 100px
    font-weight: 600
    +fs(18px)
    line-height: 1.2
    +screen('max', 1023 )
      +fs(16px)
      margin-bottom: 0.8rem
  &__content
    line-height: 1.2
    +fs(18px)
    +screen('max', 1200)
      +fs(16px)
    +screen('max', 1023)
      +fs(14px)
    p
      margin-bottom: 1rem
      +screen('max', 767)
        margin-bottom: 0.3rem
    a
      color: var(--osnovnye-color-1)
      &:hover
        color: var(--osnovnye-color-1-hover)
    h1,h2,h3,h4
      margin: 2rem 0 1rem
      &:first-child
        margin-top: 0
    ul
      list-style: disc inside
    li
      margin-bottom: 0