html
  font-size: 10px
body
  font-size: 16px
  font-weight: 400
  font-family: var(--font-primary)
  color: var(--osnovnye-text)
  &.hidden
    overflow: hidden
.inner
  max-width: 1208px
  width: 100%
  margin: 0 auto
  box-sizing: border-box
  +screen('max', 1208)
    padding-inline: 5rem
  +screen('max', 1023)
    padding-inline: 3rem
  +screen('max', 767)
    padding-inline: 1rem
main
  padding-top: 84px
  +screen('max', 767)
    padding-top: 52px

.section
  margin: 10rem 0
  +screen('max', 1200)
    margin: 6rem 0
  +screen('max', 767)
    margin: 4rem 0

.tag
  +fs(12px)
  font-weight: 600
  text-transform: uppercase
  color: var(--osnovnye-grey-dark)
  background: var(--osnovnye-bg)
  border-radius: var(--border-radius)
  padding: 0.5rem 1.2rem 0.6rem
  transition: .5s ease
  display: inline-block
  white-space: nowrap
  +screen('max', 767)
    +fs(10px)
  &.active
    background: var(--osnovnye-color-1)
    color: var(--osnovnye-white)
    &:hover
      background: var(--osnovnye-color-1-hover)
      color: var(--osnovnye-white)
  &:hover
    color: var(--osnovnye-text)