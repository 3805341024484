.note
  &__list
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 1.6rem
    +screen('max', 767)
      gap: .8rem
  &__link
    display: flex
    justify-content: space-between
    align-items: center
    background: var(--osnovnye-color-1)
    transition: .5s ease
    border-radius: var(--border-radius)
    &:hover
      background: var(--osnovnye-color-1-hover)
    +screen('max', 767)
      flex-flow: column-reverse
      align-items: flex-start
  &__content
    padding: 4rem 3rem
    box-sizing: border-box
    color: var(--osnovnye-white)
    line-height: 1.2
    +screen('max', 1200)
      padding: 3rem
    +screen('max', 1023)
      padding: 2.4rem 2rem
    +screen('max', 767)
      padding: 0 1.4rem 1.8rem
  &__title
    display: block
    +fs(20px)
    font-weight: 600
    margin-bottom: 0.6rem
    +screen('max', 1200)
      +fs(18px)
    +screen('max', 767)
      +fs(16px)
  &__sign
    +fs(18px)
    line-height: 1.2
    max-width: 350px
    display: block
    +screen('max', 1200)
      +fs(16px)
    +screen('max', 767)
      +fs(14px)
  &__pic
    vertical-align: middle
    flex-shrink: 0
    +screen('max', 1200)
      width: 124px
    +screen('max', 1023)
      width: 80px
    +screen('max', 767)
      width: 64px