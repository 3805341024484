.about-info
  &__inner
    display: flex
    align-items: flex-start
    flex-flow: row-reverse
    gap: 7rem
    +screen('max', 1200)
      display: block
  &__pic
    max-width: 391px
    width: 50%
    vertical-align: middle
    +screen('max', 1200)
      width: 40%
      float: left
      margin-right: 3rem
      margin-bottom: 2rem
    +screen('max', 767)
      width: 100%
      margin-right: 0
  &__content
    h3
      margin-bottom: 2rem
    p, li
      +fs(18px)
      +screen('max', 1023)
        +fs(16px)
    p
      margin-bottom: 1rem
    li
      padding-left: 2rem
      position: relative
      margin-bottom: 0.5rem
      &:before
        content: '•'
        position: absolute
        left: 0
        top: 0

.about-contacts
  margin-bottom: 1.6rem
  &__inner
    display: flex
    border-radius: var(--border-radius)
    &.reverse
      flex-flow: row-reverse
      +screen('max', 767)
        flex-flow: column-reverse
      .about-contacts__left
        border-radius: 0 var(--border-radius) var(--border-radius) 0
      iframe
        border-radius: var(--border-radius) 0 0 var(--border-radius)
    +screen('max', 767)
      flex-flow: column-reverse
  &__left
    width: 40%
    max-width: 424px
    background: var(--osnovnye-bg)
    padding: 10rem 7rem
    box-sizing: border-box
    border-radius: var(--border-radius) 0 0 var(--border-radius)
    +screen('max', 1200)
      padding: 7rem 7rem 6rem
    +screen('max', 1023)
      padding: 7rem 4rem 5rem
      width: 50%
    +screen('max', 767)
      width: 100%
      padding: 2rem 1.4rem
      border-radius: 0 0 var(--border-radius) var(--border-radius)
  &__title
    +fs(20px)
    font-weight: 600
    margin-bottom: 1rem
  &__addr
    +fs(18px)
  &__phone
    +fs(30px)
    color: var(--osnovnye-color-1)
    margin: 1rem 0
    font-weight: 600
    display: block
    line-height: 1
    +screen('max', 767)
      +fs(20px)
    &:hover
      color: var(--osnovnye-color-1-hover)
  &__mail
    +fs(18px)
    color: var(--osnovnye-color-1)
    margin: 1rem 0
    line-height: 1
    &:hover
      color: var(--osnovnye-color-1-hover)
  &__caption
    margin-top: 2rem
    margin-bottom: 0.6rem 
    +fs(12px)
    font-weight: 600
    text-transform: uppercase
  &__time
    +fs(18px)
    line-height: 1.2
    +screen('max', 767)
      +fs(16px)
  &__btn
    margin-top: 2rem
    +screen('max', 767)
      width: 100%
  &__right
    flex-grow: 1
    +screen('max', 767)
      height: 200px
    iframe
      height: 100%
      border-radius: 0 var(--border-radius) var(--border-radius) 0
      +screen('max', 767)
        border-radius: var(--border-radius) var(--border-radius) 0 0