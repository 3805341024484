.docs
  &__inner
    display: flex
    gap: 4.5rem
    +screen('max', 1200)
      gap: 1.6rem
    +screen('max', 1023)
      flex-flow: column-reverse
      gap: 0
  &__left
    max-width: 860px
    width: 75%
    flex-shrink: 0
    +screen('max', 1023)
      width: 100%
  &__right
    position: relative
  &__mark-head
    position: sticky
    top: 100px
    +screen('max', 767)
      border-bottom: 3px solid var(--osnovnye-grey-light-lines)
      margin-bottom: 2rem
    &[open]
      .docs__mark-title:after
        transform: translateY(-50%) rotate(180deg)
  &__mark-title
    display: none
    font-weight: 600
    margin-bottom: 2rem
    +fs(16px)
    position: relative
    &:after
      content: ''
      width: 24px
      height: 24px
      position: absolute
      right: 0
      top: 50%
      transform: translateY(-50%)
      background-image: url(../icon/arrow-down.svg)
      background-repeat: no-repeat
      background-position: center
      background-size: 24px
      transition: .5s ease
    +screen('max', 767)
      display: block
  &__mark
    display: flex
    flex-wrap: wrap
    gap: .6rem
    margin-bottom: 3rem
    +screen('max', 1023)
      margin-bottom: 2rem
  &__count
    +fs(18px)
    line-height: 1.2
    padding-bottom: 2rem
    border-bottom: 1px solid var(--osnovnye-grey-light-lines)
  &__item
    padding-block: 2rem
    border-bottom: 1px solid var(--osnovnye-grey-light-lines)
  &__info
    display: flex
    flex-wrap: wrap
    gap: 2rem
    +fs(12px)
    color: var(--osnovnye-grey-dark)
    font-weight: 600
    text-transform: uppercase
    line-height: 1
    margin-bottom: 0.8rem
  &__title
    margin-bottom: 0.6rem
    line-height: 1.2
  &__download
    color: var(--osnovnye-color-1)
    font-weight: 600
    &:hover
      color: var(--osnovnye-color-1-hover)
