.info-card
  margin-top: 8rem
  +screen('max', 1200)
    margin-top: 6rem
  +screen('max', 767)
    position: relative
    padding-bottom: 50px
  &__head
    display: flex
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid var(--osnovnye-grey-light-lines)
    gap: 2rem
    scrollbar-width: 0
    +screen('max', 767)
      overflow: auto
    &::-webkit-scrollbar
      display: none
  &__nav
    display: flex
    gap: min(10rem, 5.5vw)
  &__btn
    +fs(18px)
    color: var(--osnovnye-grey-dark)
    padding-block: 1.2rem 2.4rem
    border-bottom: 4px solid transparent
    transition: .5s ease
    +screen('max', 1023)
      +fs(16px)
    +screen('max', 767)
      +fs(14px)
      padding-bottom: 2rem
    &.active
      color: var(--osnovnye-text)
      border-bottom-color: var(--osnovnye-color-1)
    &:hover
      color: var(--osnovnye-text)
  &__body
    margin-top: 4rem
  &__wrap
    display: flex
    align-items: center
    gap: min(6.4rem, 3.5vw)
    position: relative
    +screen('max', 1023)
      align-items: flex-start
      gap: 1.6rem
    +screen('max', 767)
      flex-flow: column-reverse
      padding-bottom: 70px
  &__title
    margin-bottom: 2rem
  &__txt
    line-height: 1.2
    +fs(18px)
    +screen('max', 1023)
      +fs(16px)
    ul
      list-style: disc inside
    li
      margin-bottom: 0
    br
      line-height: 0.6
  &__pic
    max-width: 596px
    vertical-align: middle
    border-radius: var(--border-radius)
    width: 50%
    +screen('max', 767)
      width: 100%
  &__control
    margin-top: 2rem
    display: flex
    gap: 1rem
    +screen('max', 1023)
      position: absolute
      right: 0
      bottom: 5vw
      width: 50%
    +screen('max', 767)
      width: 100%
      bottom: 0
  &__more
    +screen('max', 767)
      width: 46%
  &__box
    display: none
  &__all
    +screen('max', 767)
      position: absolute
      bottom: 0
      width: calc(100% - 2rem)
      left: 1rem