.search
  &__action
    position: relative
  &__field
    width: 100%
    padding-right: 120px
  &__btn
    position: absolute
    top: 0
    right: 0
    height: 100%
  &__info
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 2rem
    +screen('max', 767)
      display: block
  &__count
    color: var(--osnovnye-grey-dark)
    +screen('max', 767)
      margin-bottom: 1rem
  &__sort
    display: flex
    justify-content: flex-end
    align-items: center
    gap: 1rem
    +screen('max', 767)
      +fs(14px)
      justify-content: flex-start
  &__tag
    +fs(12px)
    font-weight: 600
    text-transform: uppercase
    padding: 0.5rem 1.2rem 0.6rem
    border-radius: var(--border-radius)
    border: 1px solid var(--osnovnye-color-1)
    display: inline-block
    cursor: pointer
    transition: .5s ease
    &:hover
      border-color: var(--osnovnye-grey-light-lines)
  &__item
    padding-block: 2rem 3rem
    border-bottom: 1px solid var(--osnovnye-grey-light-lines)
    line-height: 1.2
  &__title
    display: block
    +fs(20px)
    margin-bottom: 0.5rem
    font-weight: 600
    +screen('max', 767)
      +fs(16px)
    b
      font-weight: 700
    &:hover
      color: var(--osnovnye-color-1-hover)
  &__txt
    margin-bottom: 1.4rem
    text-overflow: ellipsis
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    display: -webkit-box
    overflow: hidden
    max-height: 60px
    +screen('max', 767)
      +fs(14px)
      margin-bottom: 1rem
  &__link
    font-weight: 600
    color: var(--osnovnye-color-1)
    +screen('max', 767)
      +fs(14px)
    &:hover
      color: var(--osnovnye-color-1-hover)
  &__info-sr
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 1.4rem
    font-weight: 600
    color: var(--osnovnye-grey-dark)
    +screen('max', 767)
      display: block
  &__date
    +fs(14px)
    +screen('max', 767)
      +fs(12px)
      display: block
      margin-bottom: 1rem
  &__tgs
    +screen('max', 767)
      display: flex
    &:before
      content: 'Теги'
      text-transform: none
      display: inline-block
      margin-right: 1rem
      +fs(14px)
      font-family: var(--font-primary)
      +screen('max', 767)
        +fs(14px)
  &__tg
    text-transform: uppercase
    +fs(12px)
  &__caption
    +screen('max', 370)
      display: none
  &__pagination
    margin-top: 3rem