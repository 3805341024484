.cards
  margin-top: -3rem
  &__tags
    padding-block: 2rem
    display: flex
    flex-wrap: wrap
    gap: .6rem
  &__item
    padding: 9rem 8.6rem
    box-sizing: border-box
    border-radius: var(--border-radius)
    display: flex
    align-items: flex-start
    gap: 7.5rem
    +screen('max', 1200)
      padding: 6rem
      gap: 5rem
    +screen('max', 1023)
      padding: 4rem
      gap: 3rem
    +screen('max', 767)
      flex-flow: column
      padding: 2rem
      margin-bottom: 2rem
    &:nth-of-type(2n + 1)
      background: var(--osnovnye-bg)
      .btn_secondary
        background: var(--osnovnye-white)
  &__pic
    box-shadow: 0px 2px 0px 0px #A2AAB1, 0px 30px 40px 0px rgba(90, 112, 144, 0.30)
    border-radius: var(--border-radius-btn)
    vertical-align: middle
    +screen('max', 1200)
      width: 37%
    +screen('max', 767)
      width: 100%
  &__title
    margin-bottom: 1rem
  &__txt
    line-height: 1.2
    margin-bottom: 0.8rem
    +screen('max', 767)
      +fs(14px)
  &__caption
    font-weight: 600
    line-height: 1.2
    margin-bottom: 0.6rem
    display: block
    +screen('max', 767)
      +fs(14px)
  &__tarifs
    display: flex
    flex-wrap: wrap
    gap: .6rem
  &__tarif
    +fs(12px)
    font-weight: 600
    text-transform: uppercase
    padding: 0.5rem 1.2rem 0.6rem
    border-radius: var(--border-radius)
    border: 1px solid var(--osnovnye-color-1)
  &__control
    margin-top: 2rem
    display: flex
    gap: 1.6rem
    +screen('max', 767)
      flex-flow: column
      gap: .8rem

.card-banner
  overflow: hidden
  margin-top: -3rem
  &__container
    padding-left: min(8.6rem, 4.5vw)
    padding-right: 6rem
    box-sizing: border-box
    display: flex
    align-items: flex-end
    gap: 6rem
    position: relative
    +screen('max', 1200)
      padding-right: 0
      gap: 4rem
    +screen('max', 1023)
      gap: 0
      padding-left: 5rem
    +screen('max', 767)
      flex-flow: column-reverse
      justify-content: center
      padding-left: 0
    &:before
      content: ''
      width: 100%
      height: calc(100% - 30px)
      position: absolute
      bottom: 0
      left: 0
      border-radius: var(--border-radius)
      background: var(--osnovnye-bg)
      z-index: -1
  &__content
    padding-bottom: 7rem
    +screen('max', 1023)
      padding-bottom: 4rem
    +screen('max', 767)
      padding-inline: 1.4rem
      box-sizing: border-box
  &__title
    margin-bottom: 1rem
  &__txt
    line-height: 1.2
  &__btn
    margin-top: 8rem
    +screen('max', 1200)
      margin-top: 1rem
  &__pic
    +screen('max', 1200)
      width: 50%
    +screen('max', 1023)
      width: 60%
      margin-right: -50px
    +screen('max', 767)
      width: 100%
      margin-right: 0

.card-info
  &__container
    display: flex
    align-items: flex-start
    gap: 5rem
    position: relative
    padding: 0 5rem 6rem
    +screen('max', 1200)
      gap: 3rem
    +screen('max', 1023)
      padding: 0 3rem 4rem
    +screen('max', 767)
      display: block
      padding: 0 2rem 3rem
    &:before
      content: ''
      width: 100%
      height: calc(100% - 55px)
      position: absolute
      bottom: 0
      left: 0
      border-radius: var(--border-radius)
      background: var(--osnovnye-bg)
      z-index: -1
  &__pic
    width: 40%
    max-width: 342px
    vertical-align: middle
    border-radius: 20px
    box-shadow: 0px 2px 0px 0px #A2AAB1, 0px 30px 40px 0px rgba(90, 112, 144, 0.30)
    +screen('max', 1200)
      width: 35%
    +screen('max', 767)
      width: 100%
      margin-bottom: 2rem
  &__title
    padding-block: 1.4rem 1rem
  &__caption
    margin: 1rem 0
  &__txt
    line-height: 1.2
    margin: 1rem 0
  &__list
    line-height: 1
    li
      padding-left: 2rem
      position: relative
      margin-bottom: 0.5rem
      &:before
        content: '•'
        position: absolute
        left: 0
        top: 0
  &__btn
    margin-top: 1rem

.card-about
  &__inner
    display: flex
    align-items: flex-start
    flex-flow: row-reverse
    gap: 7rem
    +screen('max', 1200)
      display: block
  &__pic
    max-width: 391px
    width: 50%
    vertical-align: middle
    +screen('max', 1200)
      width: 40%
      float: right
      margin-left: 2rem
      margin-bottom: 2rem
    +screen('max', 575)
      width: 100%
      margin-left: 0
      float: none
  &__content
    h3
      margin-bottom: 2rem
    p, li
      +fs(18px)
      +screen('max', 1200)
        +fs(16px)
    p
      margin-bottom: 1rem
    li
      padding-left: 2rem
      position: relative
      margin-bottom: 0.5rem
      &:before
        content: '•'
        position: absolute
        left: 0
        top: 0