.news-index
  &__head
    display: flex
    justify-content: space-between
    align-items: center
    gap: 2rem
    margin-bottom: 2rem
  &__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 1.6rem
    scrollbar-width: 0
    &::-webkit-scrollbar
      display: none
    +screen('max', 767)
      grid-template-columns: repeat(3, 228px)
      overflow: auto
  &__link
    background: var(--osnovnye-bg)
    border-radius: var(--border-radius)
    display: block
    transition: .5s ease
    &:hover
      background: var(--osnovnye-bg-hover)
  &__pic
    width: 100%
    vertical-align: middle
    border-radius: var(--border-radius)
  &__content
    padding: 2rem 3rem 3rem
    box-sizing: border-box
    display: flex
    justify-content: space-between
    flex-flow: column
    gap: 1rem
    min-height: 170px
    +screen('max', 1023)
      padding: 1.4rem 2rem 2rem
      min-height: 128px
  &__date
    +fs(12px)
    font-weight: 600
    line-height: 1
  &__title
    flex-grow: 1
    +fs(18px)
    line-height: 1.2
    +screen('max', 1023)
      +fs(14px)
  &__tags
    display: flex
    flex-wrap: wrap
    gap: .2rem 2rem
    +fs(12px)
    text-transform: uppercase
    color: var(--osnovnye-grey-dark)
    font-weight: 600
    +screen('max', 1023)
      +fs(10px)
      gap: 2px

.news-list
  &__inner
    display: flex
    gap: 4.5rem
    +screen('max', 1200)
      gap: 1.6rem
    +screen('max', 1023)
      flex-flow: column-reverse
      gap: 0
  &__left
    max-width: 860px
    width: 75%
    flex-shrink: 0
    +screen('max', 1023)
      width: 100%
  &__right
    position: relative
  &__link
    background: var(--osnovnye-bg)
    border-radius: var(--border-radius)
    display: flex
    transition: .5s ease
    margin-bottom: 2rem
    +screen('max', 767)
      display: block
    &:hover
      background: var(--osnovnye-bg-hover)
  &__pic
    width: 100%
    vertical-align: middle
    border-radius: var(--border-radius)
    max-width: 290px
    +screen('max', 767)
      max-width: 100%
  &__content
    padding: 3rem
    box-sizing: border-box
    display: flex
    justify-content: space-between
    flex-flow: column
    gap: 1rem
    flex-shrink: 1
    +screen('max', 767)
      padding: 1.4rem 1.4rem 1.8rem 
  &__date
    +fs(12px)
    font-weight: 600
    line-height: 1
    margin-bottom: 1rem
    display: block
  &__title
    flex-grow: 1
    +fs(16px)
    line-height: 1.2
    display: block
    font-weight: 600
    margin-bottom: 0.6rem
    +screen('max', 767)
      +fs(14px)
  &__txt
    line-height: 1.2
    +screen('max', 767)
      +fs(14px)
  &__tags
    display: flex
    flex-wrap: wrap
    gap: .5rem 2rem
    +fs(12px)
    text-transform: uppercase
    color: var(--osnovnye-grey-dark)
    font-weight: 600
    +screen('max', 767)
      +fs(10px)
  &__mark
    display: flex
    flex-wrap: wrap
    gap: .6rem
    margin-bottom: 3rem
    +screen('max', 1023)
      margin-bottom: 2rem
  &__pagination
    margin-top: 3rem
  &__mark-head
    position: sticky
    top: 100px
    +screen('max', 767)
      border-bottom: 3px solid var(--osnovnye-grey-light-lines)
      margin-bottom: 2rem
    &[open]
      .news-list__mark-title:after
        transform: translateY(-50%) rotate(180deg)
  &__mark-title
    display: none
    font-weight: 600
    margin-bottom: 2rem
    +fs(16px)
    position: relative
    &:after
      content: ''
      width: 24px
      height: 24px
      position: absolute
      right: 0
      top: 50%
      transform: translateY(-50%)
      background-image: url(../icon/arrow-down.svg)
      background-repeat: no-repeat
      background-position: center
      background-size: 24px
      transition: .5s ease
    +screen('max', 767)
      display: block

.news
  &__inner
    display: flex
    gap: 4.5rem
    +screen('max', 1200)
      gap: 1.6rem
    +screen('max', 1023)
      display: block
  &__left
    max-width: 860px
    width: 75%
    flex-shrink: 0
    +screen('max', 1023)
      width: 100%
  &__preview
    width: 100%
    vertical-align: middle
    border-radius: var(--border-radius)
    vertical-align: middle
    margin-bottom: 2rem
    +screen('max', 767)
      height: 232px
      object-fit: cover
  &__info
    display: flex
    justify-content: space-between
    align-items: center
    gap: 2rem
    flex-wrap: wrap
    margin-bottom: 1rem
  &__tags
    display: flex
    justify-content: flex-end
    flex-wrap: wrap
    gap: .6rem
    +screen('max', 767)
      justify-content: flex-start
  &__date
    +fs(14px)
    font-weight: 600
    line-height: 1
    color: var(--osnovnye-grey-dark)
  &__text
    line-height: 1.2
    +screen('max', 1023)
      +fs(14px)
    p
      margin-bottom: 1rem
    a
      color: var(--osnovnye-color-1)
      &:hover
        color: var(--osnovnye-color-1-hover)
    h1,h2,h3,h4
      margin: 2rem 0 1rem
      &:first-child
        margin-top: 0
    blockquote
      padding-left: 3rem
      border-left: 4px solid var(--osnovnye-color-1)
      display: block
      margin: 2rem 0
      b
        display: block
        font-weight: 600
      span
        display: block
        color: var(--osnovnye-grey-dark)
  &__control
    display: flex
    justify-content: space-between
    margin-top: 3rem
    padding-top: 3rem
    border-top: 1px solid var(--osnovnye-color-1)
  &__right
    +screen('max', 767)
      display: none
  &__caption
    margin-bottom: 1.6rem
  &__list
    +screen('max', 1023)
      display: grid
      grid-template-columns: repeat(3, 1fr)
      gap: 1.6rem
  &__right
    +screen('max', 1023)
      margin-top: 6rem
  &__share
    path
      transition: .5s ease
    &:hover
      path
        stroke: var(--osnovnye-color-1-hover)

.link-similar
  background: var(--osnovnye-bg)
  border-radius: var(--border-radius)
  transition: .5s ease
  margin-bottom: .8rem
  padding: 2.6rem
  box-sizing: border-box
  border-radius: var(--border-radius)
  display: block
  +screen('max', 1200)
    padding: 2rem
  +screen('max', 1023)
    display: flex
    flex-flow: column
  &:hover
    background: var(--osnovnye-bg-hover)
  &:last-of-type
    +screen('max', 1023)
      display: none
  &__date
    +fs(12px)
    font-weight: 600
    line-height: 1
    margin-bottom: .6rem
    display: block
  &__title
    flex-grow: 1
    +fs(16px)
    line-height: 1.2
    display: block
    margin-bottom: 2rem
    +screen('max', 1023)
      +fs(14px)
      flex-grow: 1
  &__txt
    line-height: 1.2
  &__tags
    display: flex
    flex-wrap: wrap
    gap: 0rem 2rem
    +fs(12px)
    text-transform: uppercase
    color: var(--osnovnye-grey-dark)
    font-weight: 600
    +screen('max', 1023)
      +fs(10px)