.pagination
  display: flex
  gap: .8rem
  flex-wrap: wrap
  &__link
    width: 32px
    height: 32px
    border-radius: 6px
    text-align: center
    line-height: 32px
    +fs(16px)
    font-weight: 600
    transition: .5s ease
    display: block
    &.active
      color: var(--osnovnye-white)
      background: var(--osnovnye-color-1)
    &:hover
      background: var(--osnovnye-bg-hover)