.btn
  display: inline-block
  padding: 1.1rem 3rem 1.5rem
  box-sizing: border-box
  border-radius: var(--border-radius-btn)
  +fs(18px)
  font-family: var(--font-primary)
  line-height: 1
  cursor: pointer
  color: var(--osnovnye-white)
  text-align: center
  text-decoration: none
  position: relative
  z-index: 2
  +screen('max', 1023)
    +fs(16px)
  +screen('max', 360)
    padding: 1.1rem 1.5rem 1.5rem
  &:before
    content: ''
    width: 100%
    height: 100%
    position: absolute
    border-radius: var(--border-radius-btn)
    background: var(--btn-gradient)
    top: 0
    left: 0
    z-index: -1
  &:after
    content: ''
    width: 100%
    height: 100%
    position: absolute
    border-radius: var(--border-radius-btn)
    top: 0
    left: 0
    background: var(--osnovnye-color-1-hover)
    opacity: 0
    z-index: -1
    transition: .35s ease-in
  &:hover
    &:after
      opacity: 1
  &_secondary
    background: var(--osnovnye-bg)
    color: var(--osnovnye-color-1)
    &:hover
      color: var(--osnovnye-color-1-hover)
      background: var(--osnovnye-bg-hover)
    &:before, &:after
      content: none