.faq
  &__container
    border-radius: var(--border-radius)
    border: 1px solid var(--osnovnye-grey-light-lines)
    padding: 5rem
    box-sizing: border-box
    +screen('max', 1200)
      padding: 3rem
    +screen('max', 1023)
      padding: 2rem
    +screen('max', 767)
      position: relative
      padding: 2rem 1.4rem 7.4rem
  &__head
    display: flex
    justify-content: space-between
    align-items: center
    gap: 2rem
    padding-bottom: 2rem
    border-bottom: 1px solid var(--osnovnye-grey-light-lines)
  &__item
    border-bottom: 1px solid var(--osnovnye-grey-light-lines)
    cursor: pointer
    &.active
      border-bottom-width: 3px
  &__title
    position: relative
    padding: 2rem 3rem 2rem 0
    font-weight: 600
    +fs(18px)
    line-height: 1.2
    transition: .5s ease
    +screen('max', 1023)
      +fs(16px)
    &:after
      content: ''
      width: 24px
      height: 24px
      position: absolute
      right: 0
      top: 50%
      transform: translateY(-50%)
      background-image: url(../icon/arrow-down.svg)
      background-repeat: no-repeat
      background-position: center
      background-size: 24px
    &:hover
      color: var(--osnovnye-color-1-hover)
      &:after
        filter: brightness(0) saturate(100%) invert(67%) sepia(74%) saturate(2959%) hue-rotate(359deg) brightness(101%) contrast(107%)
    &.active
      &:after
        transform: translateY(-50%) rotate(180deg)
  &__txt
    line-height: 1.2
    display: none
    margin-bottom: 3rem
    +screen('max', 1023)
      +fs(14px)
    p
      margin-bottom: 1rem
  &__more
    +screen('max', 767)
      position: absolute
      bottom: 1.4rem
      right: 1.4rem

.faq-list
  &__inner
    display: flex
    gap: 4.4rem
  &__left
    flex-grow: 1
  &__right
    position: relative
    max-width: 306px
    +screen('max', 1200)
      max-width: 220px
    +screen('max', 1023)
      display: none
  &__text
    +fs(18px)
    line-height: 1.2
    margin-bottom: 2rem
    +screen('max', 1200)
      +fs(16px)
    +screen('max', 600)
      +fs(14px)
  &__tags
    display: flex
    gap: .6rem
    flex-wrap: wrap
    padding-bottom: 2rem
    border-bottom: 1px solid var(--osnovnye-grey-light-lines)
  &__tag
    border-radius: 20px
    border: 1px solid var(--osnovnye-grey-light-lines)
    padding: 1rem 2.4rem 1.2rem
    box-sizing: border-box
    line-height: 1
    transition: .5s ease
    +screen('max', 600)
      +fs(12px)
      padding: 0.6rem 1.6rem .8rem
    &.active
      background: var(--osnovnye-color-1)
      border-color: var(--osnovnye-color-1)
      color: var(--osnovnye-white)
    &:hover
      color: var(--osnovnye-color-1)
  &__item
    border-bottom: 1px solid var(--osnovnye-grey-light-lines)
    cursor: pointer
    &.active
      border-bottom-width: 3px
  &__title
    position: relative
    padding: 2rem 3rem 2rem 0
    font-weight: 600
    +fs(18px)
    line-height: 1.2
    transition: .5s ease
    +screen('max', 1023)
      +fs(16px)
    &:after
      content: ''
      width: 24px
      height: 24px
      position: absolute
      right: 0
      top: 50%
      transform: translateY(-50%)
      background-image: url(../icon/arrow-down.svg)
      background-repeat: no-repeat
      background-position: center
      background-size: 24px
    &:hover
      color: var(--osnovnye-color-1-hover)
      &:after
        filter: brightness(0) saturate(100%) invert(67%) sepia(74%) saturate(2959%) hue-rotate(359deg) brightness(101%) contrast(107%)
    &.active
      &:after
        transform: translateY(-50%) rotate(180deg)
  &__txt
    line-height: 1.2
    display: none
    margin-bottom: 3rem
    +screen('max', 1023)
      +fs(14px)
    p
      margin-bottom: 1rem
  &__info
    display: flex
    flex-flow: column
    gap: 1.6rem
  &__mark-head
    +screen('max', 767)
      border-bottom: 3px solid var(--osnovnye-grey-light-lines)
      margin-bottom: 2rem
    &[open]
      .faq-list__mark-title:after
        transform: translateY(-50%) rotate(180deg)
  &__mark-title
    display: none
    font-weight: 600
    margin-bottom: 2rem
    +fs(16px)
    position: relative
    &:after
      content: ''
      width: 24px
      height: 24px
      position: absolute
      right: 0
      top: 50%
      transform: translateY(-50%)
      background-image: url(../icon/arrow-down.svg)
      background-repeat: no-repeat
      background-position: center
      background-size: 24px
      transition: .5s ease
    +screen('max', 767)
      display: block

.service-link
  border-radius: var(--border-radius)
  background: var(--osnovnye-bg)
  padding: 3rem
  box-sizing: border-box
  display: flex
  align-items: flex-start
  transition: .5s ease
  +screen('max', 1200)
    padding: 2rem
  +screen('max', 1200)
    flex-wrap: wrap-reverse
    gap: 1.6rem
  &:hover
    color: var(--osnovnye-color-1-hover)
    .service-link__pic
      filter: brightness(0) saturate(100%) invert(69%) sepia(30%) saturate(5872%) hue-rotate(0deg) brightness(104%) contrast(100%)
  &__caption
    +fs(18px)
    font-weight: 600
    line-height: 1.2
    +screen('max', 1200)
      +fs(16px)
  &__pic
    flex-shrink: 0
    vertical-align: middle
    +screen('max', 1200)
      width: 60px

.call-block
  border-radius: var(--border-radius)
  background: var(--osnovnye-bg)
  padding: 3rem
  box-sizing: border-box
  +screen('max', 1200)
    padding: 2rem
  &__caption
    margin-bottom: .6rem
    +fs(18px)
    font-weight: 600
    +screen('max', 767)
      +fs(14px)
      flex-grow: 1
      width: 100%
      margin-bottom: 0.8rem
  &__phone
    display: block
    +fs(26px)
    font-weight: 600
    line-height: 1.1
    color: var(--osnovnye-color-1)
    margin: .6rem 0 1rem
    +screen('max', 1200)
      +fs(20px)
    &:hover
      color: var(--osnovnye-color-1-hover)
  &__call
    width: 100%
    +fs(16px)
  &__social
    margin-top: .6rem
    display: flex
    justify-content: space-between
    flex-wrap: wrap
  &__soc
    svg
      vertical-align: middle
      +screen('max', 1200)
        width: 36px
        height: 36px
    path
      transition: .5s ease
    &_tg
      border-radius: 12px
      background: var(--osnovnye-color-1)
      span
        padding: 1rem 2.5rem 1.4rem 2rem
        color: var(--osnovnye-white)
        +fs(18px)
        +screen('max', 1200)
          +fs(16px)
          padding: 1rem 1.5rem 1.4rem 1rem
      &:hover
        background: var(--osnovnye-color-1-hover)
    &:hover
      path
        fill: var(--osnovnye-color-1-hover)