.callback
  &__text
    +fs(18px)
    line-height: 1.2
    margin-bottom: 4.5rem
    +screen('max', 600)
      +fs(14px)
      margin-bottom: 2rem
  &__forms
    max-width: 700px
  &__tabs
    display: flex
    border-bottom: 1px solid var(--osnovnye-grey-light-lines)
    margin-bottom: 3rem
  &__tab
    flex-grow: 1
    text-align: center
    padding-bottom: 2.4rem
    +fs(18px)
    color: var(--osnovnye-grey-dark)
    transition: .5s ease
    border-bottom: 4px solid transparent
    line-height: 1
    padding-inline: .5rem
    +screen('max', 600)
      +fs(14px)
      padding-bottom: 1.4rem
      width: 33%
    &.active
      color: var(--osnovnye-text)
      border-block-color: var(--osnovnye-color-1)
    &:hover
      color: var(--osnovnye-text)
  &__caption
    color: var(--osnovnye-grey-dark)
  &__group
    margin-bottom: 1.6rem
    display: flex
    align-items: center
    justify-content: space-between
    gap: 1rem
    +screen('max', 600)
      flex-flow: column
      align-items: flex-start
      margin-bottom: 2.4rem
  &__action
    display: none
    &:first-of-type
      display: block
  &__select
    max-width: 450px
    flex-grow: 1
    +screen('max', 600)
      width: 100%
  &__field
    max-width: 450px
    flex-grow: 1
    flex-shrink: 0
    width: 65%
    +screen('max', 600)
      width: 100%
      padding: 1.35rem 1.8rem
    &_area
      max-width: 100%
      resize: none
      width: 100%
      min-height: 100px
  &__outer-file
    max-width: 450px
    position: relative
    flex-grow: 1
    +screen('max', 600)
      width: 100%
      max-width: 100%
  &__file
    border-radius: 14px
    border: 1px solid var(--osnovnye-grey-light-lines)
    color: var(--osnovnye-color-1)
    padding: 1.4rem 1.6rem
    box-sizing: border-box
    position: relative
    display: block
    cursor: pointer
    transition: .5s ease
    &:before
      content: ''
      width: 50px
      height: 50px
      border-radius: 14px
      position: absolute
      top: 0
      right: 0
      background-color: var(--osnovnye-color-1)
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath stroke='%23fff' stroke-linecap='square' stroke-width='2' d='M18 12H6M12 6v12'/%3E%3C/svg%3E")
      background-position: center
      background-repeat: no-repeat
      transition: background .5s ease
    &:hover
      color: var(--osnovnye-color-1-hover)
      path
        fill: var(--osnovnye-color-1-hover)
      &:before
        background-color: var(--osnovnye-color-1-hover)
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath stroke='%23fff' stroke-linecap='square' stroke-width='2' d='M18 12H6M12 6v12'/%3E%3C/svg%3E")
        background-position: center
        background-repeat: no-repeat
    &.file
      color: var(--osnovnye-text)
      path
        fill: var(--osnovnye-grey-dark)
      &:before
        opacity: 0
        visibility: hidden
      & + .callback__file-change
        opacity: 1
        visibility: visible
  &__file-hidden
    display: none
  &__file-name
    display: flex
    align-items: center
    gap: 1.8rem
    text-overflow: ellipsis
    overflow: hidden
    font-weight: 600
    padding-right: 70px
    path
      transition: .5s ease
  &__file-change
    position: absolute
    right: 1.6rem
    top: 50%
    transform: translateY(-50%)
    font-weight: 600
    color: var(--osnovnye-color-1)
    opacity: 0
    visibility: hidden
    z-index: 2
    cursor: pointer
    &:hover
      color: var(--osnovnye-color-1-hover)
  &__check
    display: block
    margin-bottom: 1.8rem
    cursor: pointer
  &__hidden
    display: none
    &:checked + .callback__txt
      &::before
        border-color: var(--color-tertiary)
      &::after
        opacity: 1
    &.error + .callback__txt
      &::before
        border-color: var(--osnovnye-error)
  &__txt
    padding-left: 30px
    position: relative
    +fs(16px)
    line-height: 1.2
    vertical-align: middle
    transition: .5s ease
    display: block
    +screen('max', 600)
      +fs(14px)
    a
      color: var(--osnovnye-color-1)
      &:hover
        color: var(--osnovnye-color-1-hover)
    &:before
      content: ''
      width: 16px
      height: 16px
      border-radius: 4px
      border: 1px solid var(--osnovnye-grey-dark)
      background: #FFF
      position: absolute
      left: 0
      top: 3px
      transition: .5s ease
      box-sizing: border-box
    &:after
      content: ''
      width: 16px
      height: 16px
      border-radius: 4px
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath stroke='%23DC8809' stroke-linecap='square' stroke-linejoin='round' stroke-width='2' d='m2.625 7 2.91749 2.625 5.83251-5.25'/%3E%3C/svg%3E")
      background-position: center
      background-repeat: no-repeat
      position: absolute
      left: 0
      top: 3px
      transition: .5s ease
      opacity: 0
    &:hover
      &:before
        border-color: var(--osnovnye-color-1-hover)
    