.services
  &__txt
    +fs(18px)
    line-height: 1.2
    margin-bottom: 4.5rem
    +screen('max', 1200)
      +fs(16px)
      margin-bottom: 3rem
    +screen('max', 1023)
      +fs(14px)
      margin-bottom: 2rem
  &__list
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 1.6rem
    +screen('max', 1023)
      gap: .8rem
    +screen('max', 767)
      grid-template-columns: 1fr
  &__link
    border: 1px solid var(--osnovnye-grey-light-lines)
    border-radius: var(--border-radius)
    display: flex
    justify-content: space-between
    gap: 3rem
    padding: 3.5rem 1.6rem 3rem 3rem
    transition: .5s ease
    +screen('max', 1023)
      padding: 3rem
      flex-flow: column-reverse
      align-items: flex-start
      justify-content: flex-end
      gap: 2rem
    +screen('max', 600)
      padding: 2rem 1.4rem
      gap: 1.4rem
    &:hover
      border-color: var(--osnovnye-grey-dark)
      .nav-block__more
        color: var(--osnovnye-color-1-hover)
  &__cation
    display: block
    font-weight: 600
    +fs(18px)
    line-height: 1.2
    margin-bottom: .5rem
    +screen('max', 600)
      +fs(16px)
  &__text
    +screen('max', 600)
      +fs(14px)
  &__icon
    +screen('max', 600)
      width: 50px
      flex-shrink: 0

.service-call
  margin-top: 10rem
  +screen('max', 1200)
    margin-top: 7rem
  +screen('max', 1023)
    margin-top: 5rem
  &__box
    background: var(--osnovnye-bg)
    border-radius: var(--border-radius)
    padding: 5rem 8.6rem
    +screen('max', 1023)
      padding: 5rem
    +screen('max', 700)
      gap: 1.2rem
    +screen('max', 600)
      padding: 2rem 1.4rem
  &__title
    margin-bottom: 1rem
  &__txt
    line-height: 1.2
    margin-bottom: 3rem