:root
  --font-primary: 'Segoe UI', Arial, Helvetica, sans-serif
  --font-secondary: 'Open Sans', Arial, Helvetica, sans-serif
  --osnovnye-white: #fff
  --osnovnye-text: #333
  --osnovnye-bg: #EEF4F9
  --osnovnye-bg-hover: #e5edf5
  --osnovnye-bg-input-50: rgba(238, 244, 249, 0.5)
  --osnovnye-color-1: #2196F3
  --osnovnye-color-1-hover: #0071CC
  --osnovnye-color-2: #eec484
  --osnovnye-color-3: #0d52a0
  --osnovnye-grey-light-lines: #e3e8eb
  --osnovnye-grey-dark: #90a4ae
  --osnovnye-error: #ff4224
  --osnovnye-udalit-porcelain: #eceff1
  --osnovnye-udalit-botticelli: #d7dfec
  --osnovnye-udalit-nero: rgba(255, 255, 255, 0)
  --osnovnye-udalit-japanese-laurel: #ffab00
  --osnovnye-udalit-alabaster: #f9f9f9
  --osnovnye-udalit-alto: #d3d3d3
  --osnovnye-udalit-emperor: #555
  --logo-logo-4b5c66: #4b5c66
  --logo-logo-e1042d: #008ae0
  --logo-logo-ee712a: #ee712a
  --logo-logo-0d52a0: #0d52a0
  --logo-logo-009fe3: #009fe3
  --logo-logo-e1042d: #e1042d
  --border-radius: 20px
  --border-radius-btn: 14px
  --btn-gradient: linear-gradient(90deg, #2196F3 0%, #0071CC 100%)