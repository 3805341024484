.rate
  &__select-city
    +fs(26px)
    font-weight: 600
    padding-left: 40px
    position: relative
    line-height: 1
    padding-bottom: 1.5rem
    margin-top: -1.5rem
    border-bottom: 2px solid var(--osnovnye-grey-light-lines)
    +screen('max', 930)
      +fs(20px)
    +screen('max', 700)
      +fs(16px)
    &:before
      content: ''
      width: 24px
      height: 24px
      position: absolute
      left: 0
      top: 2px
      background-image: url(../icon/arrow-down.svg)
      background-repeat: no-repeat
      background-position: center
      background-size: cover
      +screen('max', 700)
        top: -2px
  &__txt
    +fs(18px)
    margin: 4rem 0
    +screen('max', 1060)
      +fs(16px)
      margin: 2.6rem 0
    +screen('max', 900)
      +fs(14px)
  &__line
    display: flex
    align-items: center
    padding-block: 2rem
    border-top: 1px solid var(--osnovnye-grey-light-lines)
    +screen('max', 700)
      padding-block: 1.4rem
      flex-wrap: wrap
      gap: 1.4rem
  &__name
    width: 176px
    flex-shrink: 0
    +fs(14px)
    line-height: 1
    +screen('max', 1060)
      width: 140px
      +fs(12px)
    +screen('max', 900)
      width: 96px
    +screen('max', 700)
      width: 100%
  &__control
    display: flex
    gap: .6rem
    flex-grow: 1
    +screen('max', 700)
      flex-wrap: wrap
  &__btn
    border-radius: 10px
    padding: 1rem
    box-sizing: border-box
    flex-grow: 1
    display: flex
    border: 1px solid var(--osnovnye-grey-light-lines)
    display: flex
    gap: 1rem
    align-items: center
    background: var(--osnovnye-bg-input-50)
    color: var(--osnovnye-grey-dark)
    +fs(14px)
    text-align: left
    line-height: 1
    +screen('max', 1060)
      flex-flow: column
      justify-content: center
      text-align: center
      +fs(12px)
    +screen('max', 700)
      width: 49%
      +fs(10px)
    &_min
      padding: .7rem .8rem
      +screen('max', 1060)
        padding: 1rem
        width: 101px
        +fs(12px)
      +screen('max', 930)
        width: 80px
      +screen('max', 700)
        width: 23%
        +fs(10px)
    &_txt
      justify-content: center
      padding: 1.3rem 2rem
      text-align: center
      +screen('max', 700)
        +fs(10px)
    &.active
      background: var(--osnovnye-white)
      color: var(--osnovnye-text)
      svg
        color: var(--osnovnye-color-1)
    &:hover
      color: var(--osnovnye-text)
      svg
        color: var(--osnovnye-grey-dark)
  &__btn-name
    font-weight: 600
    transition: .5s ease
  &__icon
    flex-shrink: 0
    width: 80px
    vertical-align: middle
    &_min
      width: 32px
      height: 32px
  &__count
    padding-block: 1.6rem
    display: flex
    justify-content: space-between
    border-top: 1px solid var(--osnovnye-grey-light-lines)
    +screen('max', 900)
      +fs(14px)
    +screen('max', 700)
      flex-flow: column-reverse
      gap: 2rem
  &__res
    font-weight: 600
    color: var(--osnovnye-grey-dark)
  &__link
    color: var(--osnovnye-color-1)
    font-weight: 600
    &:hover
      color: var(--osnovnye-color-1-hover)
  &__row
    display: grid
    align-items: center
    grid-template-columns: 32px 65px 1fr repeat(5, 65.4px)
    border-bottom: 1px solid var(--osnovnye-grey-light-lines)
    gap: 3rem
    +screen('max', 1060)
      gap: 1.6rem
    +screen('max', 700)
      gap: 0
      grid-template-columns: 40px repeat(5, calc(100% / 5.75))
    &_head
      grid-template-columns: 32px 65px 1fr 478px
      border-top: 1px solid var(--osnovnye-grey-light-lines)
      border-bottom: 2px solid var(--osnovnye-grey-dark)
      +fs(14px)
      +screen('max', 1060)
        grid-template-columns: 32px 65px 1fr 408px
        +fs(12px)
      +screen('max', 700)
        +fs(8px)
        grid-template-columns: 40px 1fr
      b
        font-weight: 600
    &:not(.rate__row_head)
      &:hover
        color: var(--osnovnye-color-1-hover)
        .rate__ico
          color: var(--osnovnye-color-1-hover)
  &__cols-row
    display: flex
    justify-content: space-between
    text-align: center
    align-items: center
    line-height: 1
    &:first-of-type
      +screen('max', 700)
        display: none
  &__cols-name
    font-weight: 600
    flex-grow: 1
    padding-block: 1.4rem
    &:first-of-type
      max-width: 203px
      border-right: 1px solid var(--osnovnye-grey-light-lines)
      +screen('max', 1060)
        max-width: 161px
  &__cols
    border-left: 1px solid var(--osnovnye-grey-light-lines)
  &__cart
    padding-bottom: 2rem
    +screen('max', 700)
      padding-top: 1rem
      padding-bottom: 1rem
      width: calc(100% / 5.5)
    &:nth-of-type(2)
      border-right: 1px solid var(--osnovnye-grey-light-lines)
      width: 131px
      +screen('max', 1060)
        width: 94px
      +screen('max', 700)
        width: calc(100% / 5.5)
    &:first-of-type
      width: 131px
      +screen('max', 1060)
        width: 104px
      +screen('max', 700)
        width: calc(100% / 5.5)
  &__ico
    transition: .5s ease
    width: 34px
    height: 34px
    color: var(--osnovnye-color-1)
  &__num
    +screen('max', 700)
      +fs(14px)
  &__price
    text-align: center
    position: relative
    &:nth-last-of-type(4), &:nth-last-of-type(5)
      left: -10px
      +screen('max', 700)
        left: 0
    &:nth-last-of-type(4)
      +screen('max', 700)
        border-right: 1px solid var(--osnovnye-grey-light-lines)
    &:nth-last-of-type(5)
      +screen('max', 700)
        border-left: 1px solid var(--osnovnye-grey-light-lines)
    &:nth-last-of-type(3)
      &:after
        content: ''
        width: 1px
        height: calc(100% + 4rem)
        position: absolute
        left: -18px
        top: -20px
        background: var(--osnovnye-grey-light-lines)
        +screen('max', 700)
          content: none
  &__route
    padding-block: 2rem
    border-right: 1px solid var(--osnovnye-grey-light-lines)
    position: relative
    padding-right: 1rem
    +screen('max', 900)
      +fs(14px)
    &:before
      content: ''
      width: 20px
      height: 20px
      position: absolute
      left: -25px
      top: 22px
      background-image: url(../icon/arrow-down.svg)
      background-repeat: no-repeat
      background-position: center
      background-size: cover
  &__content
    +fs(12px)
    font-weight: 600
    color: var(--osnovnye-grey-dark)
    display: none
  &__price
    +screen('max', 900)
      +fs(16px)
    +screen('max', 700)
      padding-block: 1.6rem
      +fs(14px)
    svg
      +screen('max', 700)
        width: 17px
        height: 17px
  &__mark,   &__route, &__name-road
    +screen('max', 700)
      display: none

.table-tarif
  +screen('max', 700)
    border-top: 1px solid var(--osnovnye-grey-light-lines)
  &__row
    display: grid
    grid-template-columns: 250px 1fr 102px
    align-items: center
    padding-block: 1.6rem
    border-bottom: 1px solid var(--osnovnye-grey-light-lines)
    +screen('max', 700)
      display: block
      position: relative
    &_head
      border-top: 1px solid var(--osnovnye-grey-light-lines)
      border-bottom: 2px solid var(--osnovnye-grey-dark)
      +screen('max', 700)
        display: none
    &:hover
      .table-tarif__route
        color: var(--osnovnye-color-1)
      .table-tarif__change
        opacity: 1
        visibility: visible
    b
      font-weight: 600
  &__ico
    transition: .5s ease
    width: 34px
    height: 34px
    color: var(--osnovnye-color-1)
  &__price
    text-align: right
    font-weight: 700
    +screen('max', 900)
      +fs(14px)
    +screen('max', 700)
      text-align: left
  &__route
    display: flex
    justify-content: space-between
    align-items: center
    +screen('max', 900)
      +fs(14px)
      padding-right: 2rem
    +screen('max', 700)
      margin: 0.8rem 0 1.6rem
  &__change
    font-weight: 600
    color: var(--osnovnye-color-1)
    opacity: 0
    visibility: hidden
    position: relative
    +screen('max', 1200)
      opacity: 1
      visibility: visible
      flex-shrink: 0
    +screen('max', 900)
      right: -2rem
    +screen('max', 700)
      position: absolute
      bottom: 1.8rem
      right: 0
    &:hover
      color: var(--osnovnye-color-1-hover)
    @media (hover: none)
      opacity: 1
      visibility: visible
      flex-shrink: 0