.banner
  &__container
    border-radius: var(--border-radius)
    background: var(--osnovnye-bg)
    padding-left: min(8.6rem, 4.5vw)
    box-sizing: border-box
    display: flex
    align-items: flex-end
    position: relative
    +screen('max', 1023)
      height: 243px
    +screen('max', 767)
      height: auto
      display: block
      padding-left: 0
  &__pic
    vertical-align: middle
    width: 64%
    +screen('max', 1200)
      width: 68%
    +screen('max', 1023)
      position: absolute
      right: -20px
      bottom: 0
      width: auto
      height: 100%
    +screen('max', 767)
      position: relative
      height: 202px
      right: 0
      object-fit: contain
      max-width: 100%
      object-position: bottom right
  &__content
    flex-shrink: 0
    width: 36%
    margin-bottom: 7rem
    +screen('max', 1200)
      margin-bottom: 5rem
      width: 40%
    +screen('max', 1023)
      width: 70%
      margin-bottom: 4rem
    +screen('max', 767)
      width: 100%
      margin-bottom: 0
      padding: 2.4rem 3rem 0
      box-sizing: border-box
  &__title
    margin-bottom: 8rem
    +screen('max', 1200)
      margin-bottom: 6rem
    +screen('max', 1023)
      margin-bottom: 4rem
    +screen('max', 767)
      margin-bottom: 2.4rem
      text-align: center
    br
      +screen('max', 360)
        display: none
  &__link
    margin-right: 0.8rem
  &__links
    +screen('max', 767)
      display: flex
      justify-content: center