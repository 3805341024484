.heading
  margin-top: 1.4rem
  margin-bottom: 3rem
  &__inner
    padding-top: 9rem
    position: relative
    +screen('max', 1200)
      padding-top: 8rem
    +screen('max', 1023)
      padding-top: 7rem
  &__title
    padding-bottom: 1.2rem
    border-bottom: 1px solid var(--osnovnye-color-1)
  .breadcrumb
    position: absolute
    top: 0
    left: 0
    +screen('max', 1208)
      left: 5rem
    +screen('max', 1023)
      left: 3rem
    +screen('max', 767)
      left: 1rem
    &__back
      +fs(14px)
      color: var(--osnovnye-grey-dark)
      margin-right: 0.8rem
      padding-right: 1.1rem
      position: relative
      &::after
        content: '|'
        position: absolute
        right: 0
        top: 0
        +screen('max', 1023)
          content: none
      svg
        vertical-align: middle
        margin-right: 1rem
        position: relative
        top: -1px
    &__text
      +fs(14px)
    &__link
      padding-right: 2rem
      position: relative
      margin-right: 1rem
      color: var(--osnovnye-grey-dark)
      +screen('max', 1023)
        display: none
      &::after
        content: ''
        width: 12px
        height: 6px
        position: absolute
        top: 50%
        right: 0
        transform: translateY(-35%)
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTMnIGhlaWdodD0nOCcgdmlld0JveD0nMCAwIDEzIDgnIGZpbGw9J25vbmUnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTEyLjM1MzYgNC4zNTM1NUMxMi41NDg4IDQuMTU4MjkgMTIuNTQ4OCAzLjg0MTcxIDEyLjM1MzYgMy42NDY0NUw5LjE3MTU3IDAuNDY0NDY2QzguOTc2MzEgMC4yNjkyMDQgOC42NTk3MyAwLjI2OTIwNCA4LjQ2NDQ3IDAuNDY0NDY2QzguMjY5MiAwLjY1OTcyOCA4LjI2OTIgMC45NzYzMTEgOC40NjQ0NyAxLjE3MTU3TDExLjI5MjkgNEw4LjQ2NDQ3IDYuODI4NDNDOC4yNjkyIDcuMDIzNjkgOC4yNjkyIDcuMzQwMjcgOC40NjQ0NyA3LjUzNTUzQzguNjU5NzMgNy43MzA4IDguOTc2MzEgNy43MzA4IDkuMTcxNTcgNy41MzU1M0wxMi4zNTM2IDQuMzUzNTVaTTAgNC41TDEyIDQuNVYzLjVMMCAzLjVMMCA0LjVaJyBmaWxsPScjOTBBNEFFJy8+PC9zdmc+Cg==")
        background-repeat: no-repeat
        background-position: center
      &:hover
        color: var(--osnovnye-color-1)
    &__active
      +screen('max', 1023)
        display: none