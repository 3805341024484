.error-page
  height: calc(100% - 84px)
  &__inner
    height: 100%
    display: flex
    justify-content: space-between
    align-items: center
    gap: 2rem
    +screen('max', 1023)
      flex-flow: column-reverse
      justify-content: center
      gap: 9rem
    +screen('max', 767)
      gap: 5rem
  &__content
    +screen('max', 1023)
      text-align: center
  &__txt
    margin: 2rem 0 4.5rem
    +fs(20px)
    line-height: 1.2
    +screen('max', 1023)
      margin: 1.2rem 0 3rem
    +screen('max', 767)
      +fs(14px)
      margin: 1.2rem 0 2.4rem
  &__pic
    width: 50%
    max-width: 660px
    +screen('max', 1023)
      width: 100%
      max-width: 500px
    +screen('max', 767)
      max-width: calc(100% - 40px)