@font-face
  font-family: 'Open Sans'
  src: url('../font/OpenSans/OpenSans-Light.woff2') format("woff2") url('../font/OpenSans/OpenSans-Light.woff') format("woff")
  font-weight: 300
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Open Sans'
  src: url('../font/OpenSans/OpenSans-LightItalic.woff2') format("woff2") url('../font/OpenSans/OpenSans-LightItalic.woff') format("woff")
  font-weight: 300
  font-style: italic
  font-display: swap

@font-face
  font-family: 'Open Sans'
  src: url('../font/OpenSans/OpenSans-Regular.woff2') format("woff2") url('../font/OpenSans/OpenSans-Regular.woff') format("woff")
  font-weight: 400
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Open Sans'
  src: url('../font/OpenSans/OpenSans-Italic.woff2') format("woff2") url('../font/OpenSans/OpenSans-Italic.woff') format("woff")
  font-weight: 400
  font-style: italic
  font-display: swap

@font-face
  font-family: 'Open Sans'
  src: url('../font/OpenSans/OpenSans-Medium.woff2') format("woff2") url('../font/OpenSans/OpenSans-Medium.woff') format("woff")
  font-weight: 500
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Open Sans'
  src: url('../font/OpenSans/OpenSans-MediumItalic.woff2') format("woff2") url('../font/OpenSans/OpenSans-MediumItalic.woff') format("woff")
  font-weight: 500
  font-style: italic
  font-display: swap

@font-face
  font-family: 'Open Sans'
  src: url('../font/OpenSans/OpenSans-SemiBold.woff2') format("woff2") url('../font/OpenSans/OpenSans-SemiBold.woff') format("woff")
  font-weight: 600
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Open Sans'
  src: url('../font/OpenSans/OpenSans-SemiBoldItalic.woff2') format("woff2") url('../font/OpenSans/OpenSans-SemiBoldItalic.woff') format("woff")
  font-weight: 600
  font-style: italic
  font-display: swap

@font-face
  font-family: 'Open Sans'
  src: url('../font/OpenSans/OpenSans-Bold.woff2') format("woff2") url('../font/OpenSans/OpenSans-Bold.woff') format("woff")
  font-weight: 700
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Open Sans'
  src: url('../font/OpenSans/OpenSans-BoldItalic.woff2') format("woff2") url('../font/OpenSans/OpenSans-BoldItalic.woff') format("woff")
  font-weight: 700
  font-style: italic
  font-display: swap

@font-face
  font-family: 'Open Sans'
  src: url('../font/OpenSans/OpenSans-ExtraBold.woff2') format("woff2") url('../font/OpenSans/OpenSans-ExtraBold.woff') format("woff")
  font-weight: 800
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Open Sans'
  src: url('../font/OpenSans/OpenSans-ExtraBoldItalic.woff2') format("woff2") url('../font/OpenSans/OpenSans-ExtraBoldItalic.woff') format("woff")
  font-weight: 800
  font-style: italic
  font-display: swap

@font-face
  font-family: 'Segoe UI'
  src: url('../font/SegoeUI/SegoeUI-SemiBold.woff2') format("woff2"), url('../font/SegoeUI/SegoeUI-SemiBold.woff') format("woff"), url('../font/SegoeUI/SegoeUI-SemiBold.ttf') format("truetype")
  font-weight: 600
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Segoe UI'
  src: url('../font/SegoeUI/SegoeUI-Italic.woff2') format("woff2"), url('../font/SegoeUI/SegoeUI-Italic.woff') format("woff"), url('../font/SegoeUI/SegoeUI-Italic.ttf') format("truetype")
  font-weight: normal
  font-style: italic
  font-display: swap

@font-face
  font-family: 'Segoe UI'
  src: url('../font/SegoeUI/SegoeUI-Light.woff2') format("woff2"), url('../font/SegoeUI/SegoeUI-Light.woff') format("woff"), url('../font/SegoeUI/SegoeUI-Light.ttf') format("truetype")
  font-weight: 300
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Segoe UI'
  src: url('../font/SegoeUI/SegoeUI.woff2') format("woff2"), url('../font/SegoeUI/SegoeUI.woff') format("woff"), url('../font/SegoeUI/SegoeUI.ttf') format("truetype")
  font-weight: normal
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Segoe UI'
  src: url('../font/SegoeUI/SegoeUI-Bold.woff2') format("woff2"), url('../font/SegoeUI/SegoeUI-Bold.woff') format("woff"), url('../font/SegoeUI/SegoeUI-Bold.ttf') format("truetype")
  font-weight: bold
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Segoe UI'
  src: url('../font/SegoeUI/SegoeUI-BoldItalic.woff2') format("woff2"), url('../font/SegoeUI/SegoeUI-BoldItalic.woff') format("woff"), url('../font/SegoeUI/SegoeUI-BoldItalic.ttf') format("truetype")
  font-weight: bold
  font-style: italic
  font-display: swap
