.header
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 99
  padding-block: 2rem
  background: var(--osnovnye-white)
  +screen('max', 767)
    padding-block: 1.35rem
  &_border, &.fix
    border-bottom: 1px solid var(--osnovnye-grey-light-lines)
  &__inner
    display: flex
    justify-content: space-between
    align-items: center
    gap: 2rem
    position: relative
  &__icon
    vertical-align: middle
    +screen('max', 767)
      width: 100px
  &__list
    display: flex
    justify-content: center
    align-items: center
    gap: min(4rem, 2.1vw)
  &__item
    margin-bottom: 0
  &__link
    &:hover
      color: var(--osnovnye-color-1-hover)
  &__right
    display: flex
    align-items: center
    justify-content: flex-end
    gap: 3rem
  &__search
    cursor: pointer
    +screen('max', 767)
      display: none
    &.view
      +screen('max', 767)
        display: block
    path
      transition: .5s ease
    &:hover
      path
        stroke: var(--osnovnye-color-1-hover)
  &__btn
    +screen('max', 767)
      display: none
  &__select-city
    padding-right: 22px
    position: relative
    cursor: pointer
    transition: .5s ease
    +screen('max', 767)
      display: none
    &:after
      content: ''
      width: 16px
      height: 16px
      position: absolute
      right: 0
      top: 2px
      background-image: url(../icon/arrow-down.svg)
      background-repeat: no-repeat
      background-position: center
    &:hover
      color: var(--osnovnye-color-1-hover)
  &__burger
    display: none
    position: relative
    z-index: 3
    width: 20px
    height: 12px
    cursor: pointer
    +screen('max', 1500)
      display: block
    span
      width: 100%
      height: 2px
      background: var(--osnovnye-color-1)
      position: absolute
      left: 0
      transition: opacity .25s ease
      transform-origin: cetner
      top: 6px
    &:before, &:after
      content: ''
      width: 100%
      height: 2px
      background: var(--osnovnye-color-1)
      position: absolute
      left: 0
      transition: top .2s ease, transform .2s ease
      transform-origin: cetner
    &:after
      top: 12px
    &.open
      &:before, &:after
        top: 5px
      &:before
        transform: rotate(45deg)
      &:after
        transform: rotate(-45deg)
      span
        opacity: 0
    +screen('min', 1200)
      display: none
  &__middle
    +screen('max', 1200)
      display: none
  &__profile
    +screen('min', 768)
      display: none
    &.hidden
      display: none!important

.nav-mobile
  position: fixed
  top: 83px
  right: 0
  border-left: 1px solid var(--osnovnye-grey-dark)
  background: var(--osnovnye-bg)
  box-shadow: 0px 10px 30px 0px rgba(13, 71, 161, 0.15)
  width: 100%
  max-width: 320px
  height: calc(100vh - 64px)
  box-sizing: border-box
  z-index: 1
  transition: .5s ease
  transform: translateX(200%)
  opacity: 0
  visibility: hidden
  &.open
    transform: translateX(0)
    opacity: 1
    visibility: visible
  +screen('max', 767)
    max-width: 100%
    top: 52px
    border-left: 0
  &__wrap
    overflow: auto
    height: 100%
  &__container
    padding: 2rem 3rem
    +screen('max', 767)
      padding: 2rem 1rem
  &__head
    font-weight: 600
    line-height: 1
    text-transform: uppercase
    padding-bottom: 1rem
    border-bottom: 3px solid var(--osnovnye-grey-light-lines)
    margin-top: 3rem
    &:first-of-type
      margin-top: 0
  &__list
    margin: 1rem 0
  &__item
    margin-bottom: 0
  &__link
    display: block
    padding-block: 1rem 1.4rem
    +fs(14px)
    line-height: 1
  &__region
    padding: 1.4rem 1rem 1.8rem
    background: var(--osnovnye-white)
    justify-content: space-between
    align-items: center
    box-sizing: border-box
    border-bottom: 1px solid var(--osnovnye-grey-light-lines)
    display: none
    +screen('max', 767)
      display: flex
  &__select-city
    padding-right: 22px
    position: relative
    cursor: pointer
    +fs(14px)
    &:after
      content: ''
      width: 16px
      height: 16px
      position: absolute
      right: 0
      top: 2px
      background-image: url(../icon/arrow-down.svg)
      background-repeat: no-repeat
      background-position: center
  &__caption
    font-weight: 600
    +fs(14px)
  &__overlay
    position: absolute
    top: 0
    left: -60vw
    width: 100vw
    height: 100vh
    z-index: -1

.search-input
  border-radius: 20px
  border: 1px solid var(--osnovnye-bg-hover)
  background: var(--osnovnye-white)
  box-shadow: 0px 10px 30px 0px rgba(13, 71, 161, 0.15)
  padding: 1.9rem 2rem 1.8rem 3rem
  box-sizing: border-box
  position: fixed
  top: 80px
  left: 0
  right: 0
  margin: 0 auto
  max-width: 1208px
  z-index: 999
  display: none
  +screen('max', 1208)
    padding: 1rem 1rem 1rem 2rem
    width: calc( 100% -  10rem)
  +screen('max', 1023)
    width: calc( 100% - 6rem)
  +screen('max', 600)
    width: calc( 100% - 2rem)
    top: 50px
  &__action
    display: flex
    justify-content: space-between
    align-items: center
    gap: 2rem
    +screen('max', 600)
      gap: 1rem
  &__txt
    font-weight: 600
    +screen('max', 600)
      display: none
  &__field
    +fs(16px)
    flex-grow: 1
    &::placeholder
      color: var(--osnovnye-grey-dark)
    &::-webkit-search-cancel-button
      filter: brightness(0) saturate(100%) invert(67%) sepia(28%) saturate(187%) hue-rotate(155deg) brightness(92%) contrast(84%)
      &:hover
        filter: brightness(0) saturate(100%) invert(69%) sepia(30%) saturate(5872%) hue-rotate(0deg) brightness(104%) contrast(100%)
