.nav-block
  &__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 1.6rem
    +screen('max', 1023)
      gap: .8rem
    +screen('max', 767)
      grid-template-columns: 1fr
  &__link
    border: 3px solid var(--osnovnye-color-1-hover)
    border-radius: var(--border-radius)
    display: flex
    justify-content: space-between
    flex-flow: row-reverse
    gap: 1.6rem
    padding: 3.5rem 3rem 3rem 1.6rem
    transition: .5s ease
    +screen('max', 1023)
      padding: 2rem .8rem 2rem 2rem
      align-items: flex-start
    &:hover
      border-color: var(--osnovnye-grey-dark)
      .nav-block__more
        color: var(--osnovnye-color-1-hover)
  &__content
    display: flex
    flex-flow: column
    justify-content: space-between
  &__title
    display: block
    font-weight: 600
    +fs(18px)
    line-height: 1.2
    margin-bottom: .5rem
    +screen('max', 1023)
      +fs(15px)
  &__more
    color: var(--osnovnye-color-1)
    font-weight: 600
    line-height: 1
    padding-top: 1rem
    transition: .5s ease
    +screen('max', 1023)
      +fs(14px)
  &__icon
    +screen('max', 1023)
      width: 70px
      flex-shrink: 0
  &__sign
    +screen('max', 1023)
      +fs(12px)

.nav-about
  &__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 1.6rem
    +screen('max', 767)
      gap: .8rem
      grid-template-columns: 1fr
  &__link
    display: flex
    justify-content: space-between
    align-items: center
    background: var(--osnovnye-color-1)
    transition: .5s ease
    border-radius: var(--border-radius)
    gap: 2rem
    &:hover
      background: var(--osnovnye-color-1-hover)
  &__content
    padding: 3rem
    box-sizing: border-box
    color: var(--osnovnye-white)
    line-height: 1.2
    +screen('max', 1023)
      padding: 2.4rem 2rem
    +screen('max', 767)
      padding: 0 1.4rem
  &__title
    display: block
    +fs(20px)
    font-weight: 600
    +screen('max', 1200)
      +fs(18px)
    +screen('max', 767)
      +fs(16px)
  &__icon
    +screen('max', 1023)
      width: 80px