.select-city-modal
  position: fixed
  top: 0
  left: 0
  z-index: 3
  width: 100%
  height: 100%
  transform: translateY(-200%)
  opacity: 0
  visibility: hidden
  transition: .5s ease
  &.open
    opacity: 1
    visibility: visible
    transform: none
  &__overlay
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: -1
  &__container
    background: rgba(51, 51, 51, 0.98)
    padding-block: 3rem 6rem
    color: var(--osnovnye-white)
    width: 100%
  &__head
    display: flex
    justify-content: space-between
    align-items: center
  &__close
    cursor: pointer
    path
      transition: .5s ease
    &:hover
      stroke: var(--osnovnye-color-1-hover)
  &__tabs-head
    display: flex
    margin: 4rem 0
    gap: 5rem
    border-bottom: 1px solid var(--osnovnye-grey-dark)
    +screen('max', 767)
      gap: 1rem
      margin: 2rem 0
  &__tabs-btn
    +fs(18px)
    color: var(--osnovnye-grey-light-lines)
    padding-bottom: 2rem
    border-bottom: 2px solid transparent
    margin-bottom: -1px
    transition: .5s ease
    +screen('max', 767)
      +fs(14px)
    &:hover
      color: var(--osnovnye-color-1-hover)
    &.active
      color: var(--osnovnye-color-1)
      border-bottom-color: var(--osnovnye-color-1)
  &__tab-box
    max-height: 70vh
    overflow: auto
    display: none
  &__row
    display: flex
    flex-wrap: wrap
    gap: 3rem
  &__caption
    font-weight: 600
    line-height: 1
    margin-top: 2rem
    &:first-of-type
      margin-top: 0
  &__col
    display: flex
    flex-flow: column
    gap: 1rem
    flex-grow: 1
  &__link
    &:hover
      color: var(--osnovnye-color-1-hover)
    &.active
      color: var(--osnovnye-color-1)