.poiter-info
  &__txt
    padding-block: 3rem
    +fs(18px)
    line-height: 1.2
  &__frame
    width: 100%
    height: 640px
    +screen('max', 767)
      height: 75vh
    iframe
      width: 100%
      height: 100%