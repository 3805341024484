.footer
  padding-top: 42px
  position: relative
  background: var(--osnovnye-bg)
  margin-top: 15rem
  +screen('max', 1200)
    margin-top: 10rem
  &:before
    content: ''
    width: 100%
    height: 42px
    position: absolute
    top: 0
    left: 0
    border-radius: 0 0 40px 40px
    background: var(--osnovnye-white)
    display: block
  &__top
    padding-block: 4rem
    display: flex
    align-items: center
    +screen('max', 767)
      padding-block: 2rem
  &__icon
    vertical-align: middle
    +screen('max', 767)
      width: 99px
  &__sign
    flex-grow: 1
    text-align: center
    +fs(25px)
    text-transform: uppercase
    +screen('max', 1023)
      +fs(20px)
      text-align: right
    +screen('max', 767)
      display: none
  &__middle
    display: flex
    padding-block: 6rem
    border-top: 2px solid var(--osnovnye-grey-light-lines)
    border-bottom: 2px solid var(--osnovnye-grey-light-lines)
    +screen('max', 767)
      padding-block: 3rem
      display: block
  &__caption
    margin-bottom: 1.6rem
    +screen('max', 767)
      +fs(14px)
      flex-grow: 1
      width: 100%
      margin-bottom: 0.8rem
  &__info
    margin-right: min(12rem, 10vw)
  &__phone
    display: block
    +fs(30px)
    font-weight: 600
    line-height: 1.1
    color: var(--osnovnye-color-1)
    margin-bottom: 2rem
    +screen('max', 1200)
      +fs(26px)
    &:hover
      color: var(--osnovnye-color-1-hover)
  &__social
    margin-top: 1.6rem
    display: flex
    flex-wrap: wrap
    gap: 1.6rem
  &__soc
    svg
      vertical-align: middle
    path
      transition: .5s ease
    &_tg
      border-radius: 12px
      background: var(--osnovnye-color-1)
      span
        padding: 1rem 2.5rem 1.4rem 2rem
        color: var(--osnovnye-white)
        +fs(18px)
      &:hover
        background: var(--osnovnye-color-1-hover)
    &:hover
      path
        fill: var(--osnovnye-color-1-hover)
  &__apps
    display: flex
    flex-flow: column
    gap: 1.2rem
    margin-right: min(12rem, 10vw)
    +screen('max', 767)
      margin-right: 0
      flex-flow: row
      margin-top: 4rem
      flex-wrap: wrap
      gap: 0 0.6rem
  &__icon
    border-radius: 6px
    border: 1px solid transparent
    transition: .5s ease
    &:hover
      border-color: var(--osnovnye-grey-dark)
  &__nav
    display: flex
    gap: min(2rem, 2vw)
    +screen('max', 1200)
      display: block
    +screen('max', 1023)
      display: none
  &__list
    margin-right: min(8rem, 7vw)
    &:last-of-type
      margin-right: 0
  &__item
    margin-bottom: 1.6rem
  &__link
    font-weight: 600
    color: var(--osnovnye-color-1)
    &:hover
      color: var(--osnovnye-color-1-hover)
  &__foot
    padding-block: 2.4rem
    +screen('max', 767)
      display: flex
      flex-flow: column-reverse
  &__copy
    +fs(12px)
    line-height: 1.1
    margin-bottom: 0.4rem
  &__note
    +fs(12px)
    line-height: 1.1
    text-decoration: underline
    margin-right: 3rem
    padding-right: 3rem
    position: relative
    +screen('max', 767)
      margin-right: 0
      padding-right: 0
      margin-bottom: 1.2rem
      display: block
    &:after
      content: '|'
      position: absolute
      right: 0
      +screen('max', 767)
        content: none
    &:last-of-type
      margin-right: 0
      padding-right: 0
      &::after
        content: none
    &:hover
      color: var(--osnovnye-color-1-hover)
      &:after
        color: initial