.vantage
  margin-top: 1.6rem
  &__list
    display: grid
    grid-template-columns: repeat(4, 1fr)
    gap: 1.6rem
    +screen('max', 767)
      grid-template-columns: repeat(2, 1fr)
      gap: .8rem
  &__item
    padding: 2rem 3rem 3rem
    background: var(--osnovnye-bg)
    border-radius: var(--border-radius)
    box-sizing: border-box
    +screen('max', 1200)
      padding: 2rem
    +screen('max', 767)
      padding: 1.8rem 1.4rem
  &__numb
    font-weight: 600
    line-height: 1
    color: var(--osnovnye-color-1)
    +fs(26px)
    +screen('max', 1023)
      +fs(20px)
    +screen('max', 767)
      +fs(18px)
    span
      +fs(24.75px)
      font-weight: 400
  &__sign
    margin: .5rem 0 1rem
    font-weight: 600
    line-height: 1
    text-transform: uppercase
    color: var(--osnovnye-color-1)
    +screen('max', 1023)
      +fs(12px)
    +screen('max', 767)
      +fs(10px)
      margin: 0.5rem 0
  &__txt
    +fs(14px)
    opacity: .8
    +screen('max', 1023)
      +fs(12px)
      line-height: 1