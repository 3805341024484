.group
  display: inline-block

.field
  border-radius: var(--border-radius-btn)
  border: 1px solid var(--osnovnye-grey-light-lines)
  background: var(--osnovnye-bg-input-50)
  padding: 1.3rem 1.8rem 1.4rem
  box-sizing: border-box
  +fs(16px)
  line-height: 1
  transition: .5s ease
  +screen('max', 767)
    padding: 1rem 1.8rem
  &::placeholder
    color: var(--osnovnye-grey-dark)
  &.error
    border-color: var(--osnovnye-error)

.select2-hidden-accessible
  display: none

.select2-selection
  border-radius: 14px
  border: 1px solid var(--osnovnye-grey-light-lines)
  background: #F7FAFC
  padding: 1.6rem 1.4rem 1.6rem 1.8rem
  box-sizing: border-box
  position: relative
  display: block
  z-index: 2
  &__rendered
    line-height: 1
    +fs(16px)
  &__arrow
    width: 20px
    height: 20px
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath stroke='%23DC8809' stroke-linecap='square' stroke-linejoin='round' stroke-width='2' d='m4.16675 8.33337 5.83335 5.00003 5.8333-5.00003'/%3E%3C/svg%3E")
    background-repeat: no-repeat
    background-size: contain
    background-position: center
    display: inline-block
    position: absolute
    right: 1.4rem
    top: 50%
    transform: translateY(-50%)

.select2.select2-container.select2-container--open
  z-index: 4
  position: relative

.select2-container.select2-container--open
  z-index: 3

.select2-dropdown
  display: block
  padding-inline: 1.8rem
  box-sizing: border-box
  max-height: 320px
  display: block
  overflow: auto
  &:before
    content: ''
    width: 100%
    height: calc(100% + 40px)
    bottom: 0
    left: 0
    border-radius: 14px
    border: 1px solid var(--osnovnye-grey-light-lines)
    background: var(--osnovnye-white)
    box-shadow: 0px 10px 30px 0px rgba(13, 71, 161, 0.15)
    display: block
    position: absolute
    z-index: -1
    box-sizing: border-box
  &--above
    padding-top: 1rem
    &:before
      height: 100%

.select2-search
  display: none

.select2-results
  &__option
    +fs(16px)
    margin-bottom: 0
    padding-block: 1.4rem
    border-bottom: 1px solid var(--osnovnye-grey-light-lines)
    transition: .5s ease
    cursor: pointer
    &:last-of-type
      border-bottom: 0
    &:hover
      color: var(--osnovnye-color-1)
    &--disabled
      color: var(--osnovnye-grey-dark)
    &--selected
      font-weight: 600