h1, .display-1
  color: inherit
  +fs(34px)
  font-weight: 600
  line-height: 110%
  +screen('max', 1200)
    +fs(30px)
  +screen('max', 767)
    +fs(26px)

h2, .display-2
  color: inherit
  +fs(30px)
  font-style: normal
  font-weight: 600
  line-height: 110%
  +screen('max', 1200)
    +fs(24px)
  +screen('max', 767)
    +fs(20px)

h3, .display-3
  color: inherit
  +fs(26px)
  font-style: normal
  font-weight: 600
  line-height: 110%
  +screen('max', 1200)
    +fs(20px)
  +screen('max', 767)
    +fs(18px)

h4, .display-4
  color: inherit
  +fs(20px)
  font-style: normal
  font-weight: 600
  line-height: 110%
  +screen('max', 1200)
    +fs(18px)
  +screen('max', 767)
    +fs(16px)

h5, .display-5
  color: inherit
  +fs(18px)
  font-style: normal
  font-weight: 600
  line-height: 110%
  +screen('max', 1200)
    +fs(16px)
  +screen('max', 767)
    +fs(14px)

.text-uppercase
  text-transform: uppercase
.text-right
  text-align: right
.text-center
  text-align: center
.text-underline
  text-decoration: underline
